import { ApexOptions } from "apexcharts"
import axios from "axios";
import { useContext, useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts"
import { getBaseUrl } from "../utils/baseUrl";
import { EnvContext } from "../Layout/Layout";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Container, FormControl, FormControlLabel, FormLabel, IconButton, Paper, Radio, RadioGroup, Typography } from "@mui/material";

interface IUsersCountStatsData {
    dateTime: Date,
    newUsersCount: number;
    uniquePlayersCount: number;
}

interface ITimePlayedStatsData {
    dateTime: Date,
    totalTimePlayed: number;
    newUsersTimePlayed: number;
}


const formatHours = (hours: number) => {
    const days = Math.floor(hours / 24);
    const restForDays = hours % 24;

    const exactHours = Math.floor(restForDays);

    const minutes = Math.floor(restForDays % 1 * 60);

    if (days === 0 && exactHours === 0 && minutes === 0) return "0";

    return `${days > 0 ? `${days} days, ` : ''} ${exactHours > 0 ? `${exactHours} hours, ` : ''} ${minutes > 0 ? `${minutes} minutes` : ''}`;
}

const getUsersCountChartValues = (usersCountData: IUsersCountStatsData[], type: number) => {
    const usersCountSeries = [{
        name: 'New Users Count',
        data: usersCountData.map(s => s.newUsersCount)
    }, {
        name: 'Unique Players Count',
        data: usersCountData.map(s => s.uniquePlayersCount)
    }];


    const usersCountOptions: ApexOptions = {
        chart: {
            type: type === 1 ? 'line' : 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                // endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: type === 1 ? { curve: 'straight', width: 1 } : {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: usersCountData.map(s => s.dateTime.toDateString()),
        },
        yaxis: {
            title: {
                text: 'Amount'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val.toString()
                }
            }
        }
    }

    const res = {
        series: usersCountSeries,
        options: usersCountOptions
    }

    return res;
}

const getTimePlayedChartValues = (timePlayedData: ITimePlayedStatsData[], type: number) => {
    const timePlayedSeries = [
        {
            name: 'New Users Time Played',
            data: timePlayedData.map(s => s.newUsersTimePlayed)
        },
        {
            name: 'Total Time Played',
            data: timePlayedData.map(s => s.totalTimePlayed)
        }];


    const timePlayedOptions: ApexOptions = {
        chart: {
            type: type === 1 ? 'line' : 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                // endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: type === 1 ? { curve: 'straight', width: 1 } : {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: timePlayedData.map(s => s.dateTime.toDateString()),
        },
        yaxis: {
            title: {
                text: 'Hours'
            },
            floating: false,
            decimalsInFloat: 0
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return formatHours(val);
                }
            }
        }
    }

    const res = {
        series: timePlayedSeries,
        options: timePlayedOptions
    }

    return res;
}

export const VisualStats = () => {

    const [usersCountData, setUsersCountData] = useState<IUsersCountStatsData[]>([]);
    const [timePlayedData, setTimePlayedData] = useState<ITimePlayedStatsData[]>([]);
    const envCtx = useContext(EnvContext);

    const [userCountMonthOffset, setUserCountMonthOffser] = useState<number>(0);
    const [timePlayedMonthOffset, setTimePlayedMonthOffset] = useState<number>(0);

    const [usersCountChartType, setUsersCountChartType] = useState<number>(1);
    const [timePlayedChartType, setTimePlayedChartType] = useState<number>(1);

    useEffect(() => {
        (async () => {
            const countResponse = await axios.get(`${getBaseUrl(envCtx)}/admin/users-count-chart-data?offset=${userCountMonthOffset}`);
            const countResult = countResponse.data.items.map((s: any) => ({
                dateTime: new Date(s.dateTime),
                newUsersCount: s.newUsersCount,
                uniquePlayersCount: s.uniquePlayersCount,
            } as IUsersCountStatsData));

            setUsersCountData(countResult);
        })();
    }, [envCtx, userCountMonthOffset]);

    useEffect(() => {
        (async () => {
            const timePlayedResponse = await axios.get(`${getBaseUrl(envCtx)}/admin/time-played-chart-data?offset=${timePlayedMonthOffset}`);
            const timePlayedResult = timePlayedResponse.data.items.map((s: any) => ({
                dateTime: new Date(s.dateTime),
                totalTimePlayed: s.totalTimePlayed,
                newUsersTimePlayed: s.newUsersTimePlayed
            } as ITimePlayedStatsData));

            setTimePlayedData(timePlayedResult);
        })();
    }, [envCtx, timePlayedMonthOffset]);

    const userCountMonth = useMemo(() => {
        return new Date(new Date().setMonth(new Date().getMonth() + userCountMonthOffset));
    }, [userCountMonthOffset]);

    const timePlayedMonth = useMemo(() => {
        return new Date(new Date().setMonth(new Date().getMonth() + timePlayedMonthOffset));
    }, [timePlayedMonthOffset]);

    return (
        <Container>
            <div>
                <Paper>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex' }}>
                            <IconButton onClick={() => setUserCountMonthOffser(prev => prev - 1)}>
                                <ArrowBackIosIcon />
                            </IconButton>
                            <Typography lineHeight={5}>
                                {userCountMonth.toLocaleString('En', { month: 'short', year: 'numeric' })}
                            </Typography>
                            <IconButton disabled={userCountMonthOffset === 0} onClick={() => setUserCountMonthOffser(prev => prev + 1)}>
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </div>

                        <div style={{ marginLeft: '50px' }}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Chart type</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={usersCountChartType}
                                    onChange={(e => setUsersCountChartType(+e.target.value))}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="Line" />
                                    <FormControlLabel value={2} control={<Radio />} label="Bar" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                </Paper>
                {usersCountChartType === 1
                    ? (<>
                        <div id="chart1">
                            <ReactApexChart key={'c-1'} options={getUsersCountChartValues(usersCountData, 1).options} series={getUsersCountChartValues(usersCountData, 1).series} type='line' height={350} />
                        </div>
                        <div id="html-dist1"></div>
                    </>) :
                    (<>
                        <div id="chart2">
                            <ReactApexChart key={'c-2'} options={getUsersCountChartValues(usersCountData, 2).options} series={getUsersCountChartValues(usersCountData, 2).series} type='bar' height={350} />
                        </div>
                        <div id="html-dist2"></div>
                    </>
                    )
                }
            </div>
            <div style={{ marginTop: '30px' }}>
                <Paper>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex' }}>
                            <IconButton onClick={() => setTimePlayedMonthOffset(prev => prev - 1)}>
                                <ArrowBackIosIcon />
                            </IconButton>
                            <Typography lineHeight={3}>
                                {timePlayedMonth.toLocaleString('En', { month: 'short', year: 'numeric' })}
                            </Typography>
                            <IconButton disabled={timePlayedMonthOffset === 0} onClick={() => setTimePlayedMonthOffset(prev => prev + 1)}>
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </div>
                        <div style={{ marginLeft: '50px' }}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Chart type</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={timePlayedChartType}
                                    onChange={(e => setTimePlayedChartType(+e.target.value))}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="Line" />
                                    <FormControlLabel value={2} control={<Radio />} label="Bar" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                </Paper>
                {timePlayedChartType === 1
                    ? (
                        <>
                            <div id="t-chart1">
                                <ReactApexChart key={'t-1'} options={getTimePlayedChartValues(timePlayedData, 1).options} series={getTimePlayedChartValues(timePlayedData, 1).series} type="line" height={350} />
                            </div>
                            <div id="html-t-dist1"></div>
                        </>
                    )
                    : (
                        <>
                            <div id="t-chart2">
                                <ReactApexChart key={'t-2'} options={getTimePlayedChartValues(timePlayedData, 2).options} series={getTimePlayedChartValues(timePlayedData, 2).series} type="bar" height={350} />
                            </div>
                            <div id="html-t-dist2"></div>
                        </>
                    )}
            </div>
        </Container>
    )
}